/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, YouTubeAsBackground, ColumnWrap, ColumnWrapper, Title, Image, Text, Button, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"WoodArt Slovakia"}>
        <SiteHeader set="" currentLanguage={0} />

        <Column className="--style3 --full" anim={""} name={"[[UNIsecname1]]"} animS={"3"} border={""} fullscreen={true} parallax={false} css={css`
      background-position: 44% 61%;
    `}>
          <YouTubeAsBackground autoplay loop modestbranding mute controls={false} embedId={"3IPnX4ENzSM"} />
          
          <ColumnWrap className="column__flex --center el--1 flex--center" style={{"maxWidth":1390}} anim={null} columns={"1"} fullscreen={false}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center mb--20 pb--0 pl--0 pr--0 pt--0" style={{"maxWidth":1425}} anim={"2"} animS={"5"}>
              
              <Title className="title-box fs--102 w--500 title-box--invert lh--1 mt--10" style={{"maxWidth":1106}} content={"Prémiové sviečky<br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" layout={"l4"} name={"3point"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/12445bc54d1d43a4968f42c08d7f761d_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/12445bc54d1d43a4968f42c08d7f761d_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-style: italic;\">Kvalitný prírodný vosk&nbsp;</span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/8e0dad07044a461f8f5d5c751bb2f205_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/8e0dad07044a461f8f5d5c751bb2f205_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"100% Prírodný produkt<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/39117/0809a3a0aaa5496e9e751927e790def4_s=350x_.png"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":121}} srcSet={"https://cdn.swbpg.com/t/39117/0809a3a0aaa5496e9e751927e790def4_s=350x_.png 350w"}>
              </Image>

              <Text className="text-box" content={"100% originálny výrobok<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--60 pt--60" style={{"backgroundColor":"rgba(44,45,54,1)"}} name={"px6fsgs2v5"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: var(--color-custom-1);\">Hľadáte výnimočné sviečky?<br></span><br><span style=\"caret-color: rgb(230, 230, 230); color: rgb(230, 230, 230);\">Tak to ste na správnom mieste</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center pb--80 pt--80" name={"6ysdwei2xec"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"<span style=\"color: rgb(227, 49, 49);\">!!!Čoskoro skladom!!!<br><br>Pre prednostné objednávky píšte</span><br>"}>
              </Title>

              <Button className="btn-box btn-box--hvr5 btn-box--cbtn1 btn-box--pbtn3 btn-box--shape4 btn-box--cColor1 btn-box--center" content={"Objednať<br>"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"txsx157byoo"}>
          
          <SeparateLineWrap className="--full" fullscreen={true}>
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" style={{"backgroundColor":"rgba(255, 255, 255, 1)"}} name={"pata"}>
          
          <ColumnWrap className="column__flex --center el--3 flex--top" columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--left fs--26" content={"Kontakt<br>"}>
              </Title>

              <Text className="text-box text-box--left pt--08" style={{"marginTop":0}} content={"<a href=\"tel:+421 905 310 613\" style=\"color: var(--black);\">+421 905 310 613</a><br>"}>
              </Text>

              <Text className="text-box text-box--left" style={{"marginTop":0}} content={"<span style=\"background-color: rgb(255, 255, 255);\"><a href=\"mailto:info.woodartsk@gmail.com\" style=\"color: var(--black);\">info.woodartsk@gmail.com</a></span><br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":462}}>
              
              <Title className="title-box" style={{"marginBottom":0}} content={"Andrej Nagy<br>"}>
              </Title>

              <Image style={{"maxWidth":148,"marginTop":0}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=350x_.png 350w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=660x_.png 660w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png 860w, https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/39117/8f73736f75234a1092bd20f718af13b3_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":462}}>
              
              <Title className="title-box title-box--right fs--26" content={"Návody a Starostlivosť<br>"}>
              </Title>

              <Text className="text-box text-box--right pt--08" style={{"marginTop":0}} content={"<a href=\"/navod-montaz\" style=\"background-color: rgb(255, 255, 255); color: var(--black);\">Zavesenie hodín</a><br>"}>
              </Text>

              <Text className="text-box text-box--right" style={{"marginTop":0}} content={"<a href=\"/starostlivost\" style=\"color: var(--black); background-color: rgb(255, 255, 255);\">Starostlivosť</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}